export const createLiveAgent = () => (function(d, src, c) {
  const elements = getLiveAgentIfExists();

  if (elements.length) return;

  var t = d.scripts[d.scripts.length - 1],
    s = d.createElement('script');
  s.id = 'la_x2s6df8d';
  s.async = true;
  s.src = src;
  s.onload = s.onreadystatechange = function() {
    var rs = this.readyState;
    if (rs && rs != 'complete' && rs != 'loaded') {
      return;
    }
    c(this);
  };
  t.parentElement.insertBefore(s, t.nextSibling);
})(document, 'https://servicedesk.teamdev.it/scripts/track.js', function(e) {
  LiveAgent.createButton('566zggnp', e);
});

export const removeLiveAgent = () => {
  const elements = getLiveAgentIfExists();

  if (elements.length) {
    elements.forEach(x => x.remove());
  }
}

const getLiveAgentIfExists = () => {
  const elements = document.querySelectorAll('[id*="566zggnp"]');

  return elements;
}
