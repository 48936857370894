import { loadedConfiguration, settings } from "@/settings";
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class ConfigExplorer extends Vue {

  get config() {
    return { settings, loadedConfiguration };
  }

}