

export const settings = {
  appTitle: "P<small>rospecto</small>",
  disableDefaultTranslations: false,
  ImageServerUri: ""

}

export const loadedConfiguration = {

}