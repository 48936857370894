import { render, staticRenderFns } from "./langSelector.vue?vue&type=template&id=81518510&scoped=true&"
import script from "./langSelector.ts?vue&type=script&lang=ts&"
export * from "./langSelector.ts?vue&type=script&lang=ts&"
import style0 from "./langSelector.vue?vue&type=style&index=0&id=81518510&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81518510",
  null
  
)

export default component.exports