
import Vue from "vue";
import Component from "vue-class-component";
import { IMenuDefinition } from "vue-mf-module";
import { Prop, Watch } from "vue-property-decorator";

@Component({})
export default class HomeItem extends Vue {

  @Prop()
  menu: {
    item: IMenuDefinition,
    children: IMenuDefinition[]
  };

  @Prop()
  iconStyle: unknown;

  notifications = 0;

  @Watch('menu.item.meta.notifications', { immediate: true })
  notificationsChanged(n?, o?) {
    if (n !== null && n !== undefined && n != o) {
      this.notifications = this.notifications + ((n || 0) - (o || 0))
    }
  }

  resetNotifications() {
    this.notifications = 0;
  }

}