import { MessageService } from "vue-mf-module";
import { Component, Vue, Watch } from "vue-property-decorator";
import { createLiveAgent, removeLiveAgent } from '@/utils/liveAgent';
import { store } from "@/store";

@Component
export default class LiveAgent extends Vue {
  isAuthenticated: boolean = false;

  get canSeeLiveAgent(): boolean {
    return store.state.ui.permissions.indexOf('SUPPORT.cansee') >= 0;
  }

  @Watch('isAuthenticated')
  @Watch('canSeeLiveAgent')
  valueChanged(n: boolean, o: boolean) {
    this.manageLiveAgent();
  }


  async mounted() {
    this.isAuthenticated = await MessageService.Instance.ask("AM_I_LOGGEDIN");

    MessageService.Instance.subscribe('LOG_OUT', () => {
      this.isAuthenticated = false
    });

    MessageService.Instance.subscribe('LOG_IN', () => {
      this.isAuthenticated = true;
    })


    this.manageLiveAgent();
  }

  private manageLiveAgent(): void {
    if (this.isAuthenticated && this.canSeeLiveAgent) {
      createLiveAgent();
    } else {
      removeLiveAgent();
    }
  }
}