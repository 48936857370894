import Component from "vue-class-component"
import Vue from "vue"
import { Prop, Watch } from "vue-property-decorator";

@Component({})
export default class DropDownMenu extends Vue {

  @Prop({ required: true })
  list: { value: string, label: string }[]
  isOpen: boolean = false;

  selected: string = null

  horizontalPosition() {
    var bb = this.$el.getBoundingClientRect();
    return bb.x - 200 + bb.width;
  }

  mounted() {
    if (!!this.list)
      this.selected = this.list[0].value

    this.$emit("Selected", this.selected)
  }
  verticalPosition() {
    var bb = this.$el.getBoundingClientRect();
    return bb.y + bb.height;
  }

  @Watch('selected')
  selection(l: { value: string, label: string }) {
    this.$emit("Selected", l)
  }
}