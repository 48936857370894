import Vue from 'vue';
import { Component } from "vue-property-decorator";
import { store } from "./store";
import { MessageService } from "vue-mf-module";
import LiveAgent from './components/liveAgent/liveAgent.vue';
import { transformWithProjections } from 'ol/proj';
@Component({
  components: {
    LiveAgent
  }
})
export default class App extends Vue {
  async mounted() {
    MessageService.Instance.subscribe("ROUTE_TO", (page) => {
      this.$router.push(page);
    });

    MessageService.Instance.subscribe("PERMISSIONS_CHANGED", async (p) => {
      store.actions.ui.setPermissions(p);
    });

    MessageService.Instance.reply("AVAILABLE_LOCALES", () => store.getters.ui.getAvailableLanguages());
  }
}
