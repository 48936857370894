import Vue from "vue";
import Component from "vue-class-component";
import { IProjectableModel } from "vue-mf-module";
import { Prop } from "vue-property-decorator";

@Component({})
export default class AddLinkModal extends Vue {
  
  @Prop({})
  public value: IProjectableModel<{ text: string, address: string }>;

  public text = "";
  public address = "";

  public mounted() {
    this.text = this.value.data.text;
  }

  public confirm() {
    this.value.resolve({ text: this.text, address: this.address });
  }

  public close() {
    this.value.reject();
  }

}
