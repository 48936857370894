import devtools from '@vue/devtools';
import Vue from 'vue';
import App from './App.vue';
import VueRouter from "vue-router";
import { mainRoute, routes } from "@/router";
import { unregister } from 'register-service-worker'

unregister();
// import './registerServiceWorker'; 


// if (process.env.NODE_ENV === 'development') {
//   devtools.connect(/* host, port */)
// }

Vue.use(VueRouter);
Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.config.performance = false;

import microfrontends, { MessageService } from "vue-mf-module";
Vue.use(microfrontends as any);

import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';
// import '@tabler/icons/iconfont/tabler-icons.min.css';


import { loadModules } from "./moduleLoader";
import { store, vueStore } from "@/store";

Vue.mixin({
  data: {
    __permissions: []
  },
  methods: {
    $t: function (key: string, defaultString: string = null, forceDefault = false) {
      let translation = store.getters.ui.getLabelFor(key);

      return translation || (settings.disableDefaultTranslations && !forceDefault ? key : defaultString || key);
    },
    $can(key: string) {
      return store.state.ui.permissions.indexOf(key) >= 0;
    },
    $me() {
      return store.state.ui.user;
    }
  }
});

import Toggle from "@/components/toggleButton/toggle.vue";
Vue.component('toggle', Toggle);
import ColorSelector from "@/components/colorSelector/colorSelector.vue";
Vue.component('colorSelector', ColorSelector);
import DropDown from "@/components/dropDown/dropDown.vue";
Vue.component('dropDown', DropDown);
import DropDownMenu from "@/components/dropDownMenu/dropDownMenu.vue";
Vue.component('dropDownMenu', DropDownMenu);
import GroupItem from "@/components/groupItem/GroupItem.vue";
Vue.component('groupItem', GroupItem);
import ContentEditor from "@/components/contentEditor/contentEditor.vue";
Vue.component('contentEditor', ContentEditor);

loadModules().then(() => {
  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
  });

  MessageService.Instance.reply("ASK_ROUTE", () => router.currentRoute);

  router.beforeEach(async (to, from, next) => {
    let permissions = store.getters.ui.getPermissions();

    // Controllo se ho i permessi..
    if (to.meta && to.meta.claims && permissions && permissions.length) {
      if (!(to.meta.claims as string[]).some(i => permissions.includes(i))) {
        // Non ho i permessi per la rotta richiesta.
        next('/login');
        return;
      }
    }
    next();
  });

  new Vue({
    render: h => h(App),
    store: vueStore, router,
    mounted: () => {

    }
  }).$mount('#app');
});


// preload delle librerie base usate dai sottomoduli
import "vuex-typed-store";
import "qs";
import "axios";
import "ts-md5";
import "moment";
import "@microsoft/signalr";
// import "esri-loader";
import "echarts";
import "xlsx";

import "moment/locale/it";
// import "moment/locale/en";
import "moment/locale/sq";
import "moment/locale/fr";


import VTooltip from 'v-tooltip';
import { settings } from './settings';
import moment from 'moment';

moment.locale(navigator.languages[0].substring(0, 2))
Vue.use(VTooltip);

