import Vue from "vue"
import VueRouter, { RouteConfig, Route } from "vue-router"
import Layout from "@/layout/layout.vue";
import Loading from "@/views/Loading.vue";
import Home from "@/views/Home.vue";
import Style from "@/views/Style.vue";
import ConfigExplorer from "@/views/ConfigExplorer.vue";


export function InjectRoutes(parent: RouteConfig, ...children: RouteConfig[]) {
  for (const element of children) {
    if (!parent.children.find(i => i.name === element.name)) {
      parent.children.push(element);
    }
  }
}

export const rootRoute = {
  path: "/",
  name: "void",
  redirect: "/home",
}

export const mainRoute = {
  path: "/app",
  name: "Main",
  component: Layout,
  redirect: "/home",
  children: [{
    path: "/home",
    name: "home",
    meta: { requireAuth: true },
    component: Home,
  },
  {
    path: "/config",
    name: "config",
    meta: { requireAuth: false },
    component: ConfigExplorer,
  }],
};

const style = {
  path: "/style",
  name: "style",
  component: Style
}

const loading = {
  path: "/loading",
  name: "loading",
  component: Loading,
}


export const routes: Array<RouteConfig> = [
  mainRoute, style, loading, rootRoute
];

function exploreRoutes(routes: RouteConfig[]): RouteConfig[] {
  let result = [];
  for (const route of routes) {
    result.push(route);
    if (route.children && route.children.length) {
      result.push(exploreRoutes(route.children))
    }
  }

  return result;
}






