import { mainRoute } from "@/router";
import Vue from "vue";
import Component from "vue-class-component";
import { MessageService } from "vue-mf-module";



@Component({})
export default class AppLoading extends Vue {

  async mounted() {

    try {
      var loggedin = await MessageService.Instance.ask("AM_I_LOGGEDIN");
      if (loggedin)
      {
        this.$router.push(mainRoute.redirect);
        return;
      }
    } catch {    }


    MessageService.Instance.send("ASK_LOGIN");
  }
}