import Component from "vue-class-component";
import { IMenuDefinition, MenuHelper, MessageService } from "vue-mf-module";
import Vue from "vue";
import HomeItem from "@/components/homeItem/HomeItem.vue";
import { MenuNotifications, menuType } from "vue-mf-module/dist/helpers/MenuHelper";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: { HomeItem }
})
export default class Home extends Vue {
  menu: {
    item: IMenuDefinition;
    children: IMenuDefinition[];
  }[] = [];

  get activeMenu() {
    return this.menu.filter(m => !m.item.featureflags || !m.item.featureflags.length || m.item.featureflags.find(f => this.$can(f))).sort((a, b) => (a.item.orderIndex || 10) - (b.item.orderIndex || 10));
  }

  async mounted() {
    MenuHelper.Instance.Notifications.on(MenuNotifications.menuDefinitionAdded, () => this.loadMenu());
    this.loadMenu();
  }

  @Watch('menu', { deep: true, immediate: true })
  menuChanged() {
    if (this.activeMenu.length == 1)
      this.$router.push({ name: this.activeMenu[0].item.routeName })
  }

  loadMenu() {
    this.menu = MenuHelper.Instance.getMenu(menuType.drawer);
  }

  getIconStyle(m: { item: IMenuDefinition; children: IMenuDefinition[]; }) {
    if (m.item.meta && m.item.meta.themeColor)
      return { color: m.item.meta.themeColor };
  }
}