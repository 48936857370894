import Vue from "vue";
import Component from "vue-class-component";
import drawerMenu from "@/components/drawerMenu/drawerMenu.vue";
import appHeader from "@/components/header/appHeader.vue";
import { store } from "@/store";
import { MessageService } from 'vue-mf-module';

import * as iziToast from "izitoast";
import { debounce } from "@/utils/debounce";

@Component({
  components: {
    drawerMenu, appHeader
  },
})
export default class Layout extends Vue {
  get modalLayout() {
    return store.state.ui.modalLayout;
  }

  async mounted() {
    MessageService.Instance.subscribe("SUCCESS", (message) => {
      this.successMessage(message);
    })

    MessageService.Instance.subscribe("ERROR", (error) => {
      this.erroMessage(error);
    })

    MessageService.Instance.send("MOUNTED")
  }

  successMessage = debounce((message) => {
    iziToast.default.success({
      theme: "dark",
      position: "bottomLeft",
      title: message,
      timeout: 2000,
      transitionIn: "fadeIn",
      transitionOut: "fadeOut"
    })
  }, 500);

  erroMessage = debounce((error) => {
    iziToast.default.error({
      theme: "dark",
      position: "topCenter",
      title: error.title || error,
      message: error.details || "",
      timeout: 5000,
      transitionIn: "fadeIn",
      transitionOut: "fadeOut"
    })
  }, 500);

}