import { render, staticRenderFns } from "./liveAgent.vue?vue&type=template&id=cd2b7ab8&scoped=true&"
import script from "./liveAgent.ts?vue&type=script&lang=ts&"
export * from "./liveAgent.ts?vue&type=script&lang=ts&"
import style0 from "./liveAgent.vue?vue&type=style&index=0&id=cd2b7ab8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd2b7ab8",
  null
  
)

export default component.exports