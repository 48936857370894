import Vue from "vue";
import Component from "vue-class-component";

import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Typography from '@tiptap/extension-typography'
import Document from '@tiptap/extension-document'
import Link from '@tiptap/extension-link';
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Code from '@tiptap/extension-code'
import { ColorHighlighter } from '@/helpers/colors'
// import { SmilieReplacer } from '@/helpers/smiles'
import { Prop, Watch } from "vue-property-decorator";
import { Projector } from "vue-mf-module";
import AddLinkModal from "./addLinkModal/addLinkModal.vue";

@Component({
  components: { EditorContent }
})
export default class TextEditor extends Vue {

  @Prop()
  value: string;

  @Prop({ default: false })
  disabled: boolean;

  @Prop({ default: true })
  showToolBox: boolean;

  @Prop({ default: false })
  allowLinks: boolean;

  get Value() { return this.value; }
  set Value(v) { this.$emit('input', v); }

  // @Watch('value')
  // valueChanged(v) {
  //   this.editor.commands.setContent(v);
  // }

  editor: Editor = null;

  @Watch('disabled')
  disablechanged(n: boolean, o: boolean) {
    if (n != o) {
      this.editor.setEditable(!n);
    }
  }

  async mounted() {
    const link = Link.configure({
      openOnClick: false,
      autolink: false,
      linkOnPaste: false,
      HTMLAttributes: { target: '_blank' },
    });

    this.editor = new Editor({
      extensions: [
        StarterKit,
        Highlight,
        Typography,
        Document,
        Text,
        Code,
        ColorHighlighter,
        this.allowLinks ? link : undefined,
        // SmilieReplacer,
      ], content: this.Value
    });
    this.editor.setEditable(!this.disabled);

    this.editor.on("update", (ee) => {
      if (this.disabled) return;
      this.Value = ee.editor.getHTML();
    });
  }


  async beforeDestroy() {
    this.editor.destroy();
  }

  async toggleLink() {
    if (this.editor.isActive("link")) {
      this.editor.chain().toggleMark("link").run();
      return;
    }

    const selection = this.editor.view.state.selection;
    let text = "";
    if (!selection.empty) {
      text = selection.content().content.textBetween(0, selection.content().content.size);
    }

    try {
      const result = await Projector.Instance.projectAsyncTo(AddLinkModal as any, { text, address: "" });
      this.editor.chain()
        .insertContent(`<a href="${result.address}">${result.text}</a>`)
        .run();
    } catch (err) {
      console.log("Errore inserimento link: ", err);
    }
  }
}
