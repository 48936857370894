import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";


@Component({})
export default class Describe extends Vue {

  @Prop({ default: null })
  background: string;

  show: boolean = false;
  mounted() {
    this.show = true;
  }


  prettifyXml(xml, tab=" ") {
    var formatted = '', indent= '';
    tab = tab || '\t';
    xml.trim().split(/>\s*</).forEach(function(node) {
        if (node.match( /^\/\w/ )) indent = indent.substring(tab.length); // decrease indent by one 'tab'
        formatted += indent + '<' + node + '>\r\n';
        if (node.match( /^<?\w[^>]*[^\/]$/ )) indent += tab;              // increase indent
    });
    return formatted.substring(1, formatted.length-3);
  };
}