import Vue from "vue";

export interface UIStoreActions {
  toggleMenu(value: boolean);
  setModalLayout(value: "center" | "right");
  setLanguage(model: { language: string; value: {} });
  setPermissions(model: string[]);
  setUser(model: { userId?: string; email: string; userName: string });
  setAvailableLanguages(model: string[]);
}
export interface UIStoreGetters {
  isMenuExpanded(): boolean;
  getLabelFor(key: string): string;
  getCurrentLanguage(): string;
  getAvailableLanguages(): string[];
  getPermissions(): string[];
}
export interface UIStoreModel {
  isExpanded: boolean;
  modalLayout: "center" | "right";
  locale: { [id: string]: string };
  currentLanguage: string;
  permissions: string[];
  user: {
    userId?: string;
    email: string;
    userName: string;
  };
  availableLanguages: string[];
}

export const uistore = {
  PREFIX: "ui",
  namespaced: true,
  state: {
    isExpanded: false,
    modalLayout: "right",
    locale: {},
    currentLanguage: localStorage.getItem('currentLanguage') || "it",
    permissions: [],
    user: null,
    availableLanguages: [],
  },
  getters: {
    isMenuExpanded: (state: UIStoreModel) => (): boolean => state.isExpanded,
    getLabelFor: (state: UIStoreModel) => (key: string) => state.locale[key],
    getCurrentLanguage: (state: UIStoreModel) => (): string => state.currentLanguage,
    getAvailableLanguages: (state: UIStoreModel) => (): string[] => state.availableLanguages,
    getPermissions: (state: UIStoreModel) => (): string[] => state.permissions,
  },
  mutations: {
    SET_MENU_EXPANDED(state: UIStoreModel, value: boolean) {
      state.isExpanded = value;
    },
    SET_MODAL_LAYOUT(state: UIStoreModel, value: "center" | "right") {
      state.modalLayout = value;
    },
    SET_LOCALE(state: UIStoreModel, value: {}) {
      for (const k of Object.keys(value)) {
        Vue.set(state.locale, k, value[k]);
      }
    },
    SET_LANGUAGE(state: UIStoreModel, value: string) {
      state.currentLanguage = value;
      localStorage.setItem('currentLanguage', value);
    },
    SET_PERMISSIONS(state: UIStoreModel, value: string[]) {
      state.permissions = value;
    },
    SET_USER(state: UIStoreModel, value: any) {
      state.user = value;
    },
    SET_AVAILABLE_LANGUAGES(state: UIStoreModel, value: string[]) {
      state.availableLanguages = value;
    },
  },
  actions: {
    toggleMenu(context, model: boolean) {
      context.commit("SET_MENU_EXPANDED", model);
    },
    setModalLayout(context, value: "center" | "right") {
      context.commit("SET_MODAL_LAYOUT", value);
    },
    setLanguage(
      context,
      model: {
        language: string;
        value: {};
      }
    ) {
      context.commit("SET_LANGUAGE", model.language);
      context.commit("SET_LOCALE", model.value);
    },
    setPermissions(context, model: string[]) {
      context.commit("SET_PERMISSIONS", model);
    },
    setUser(context, model) {
      context.commit("SET_USER", model);
    },
    setAvailableLanguages(context, model) {
      context.commit("SET_AVAILABLE_LANGUAGES", model);
    },
  },
};
