import Component from "vue-class-component";
import Vue from "vue";
import { store } from "@/store";

@Component({})
export default class ModalLayout extends Vue {

  get modalLayout(): "center" | "right" {
    return store.state.ui.modalLayout;
  }
  set modalLayout(value: "center" | "right") {
    store.actions.ui.setModalLayout(value);
  }

} 