import Vue from 'vue'
import Vuex from 'vuex'
import { CreateTypedStoreProxy } from 'vuex-typed-store';
import { uistore, UIStoreActions, UIStoreGetters, UIStoreModel } from './ui.store';

Vue.use(Vuex)

const _store = {
  modules: {
    [uistore.PREFIX]: uistore
  }
};

interface StoreActions {
  ui: UIStoreActions;
}
interface StoreGetters {
  ui: UIStoreGetters;
}
interface StoreModel {
  ui: UIStoreModel;
}

export const vueStore = new Vuex.Store(_store);
export const store = CreateTypedStoreProxy<StoreModel, StoreActions, StoreGetters>(vueStore);