import { store } from "@/store";
import Vue from "vue";
import Component from "vue-class-component";
import modalLayout from "@/components/modalLayout/modalLayout.vue";

@Component({
  components: { modalLayout }
})
export default class AppHeader extends Vue {

  get expanded() { return store.getters.ui.isMenuExpanded(); }

	toggleMenu() {
    store.actions.ui.toggleMenu(!this.expanded);
  }
}