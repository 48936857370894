import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { store } from "@/store";
import axios from "axios";

@Component
export default class LangSelector extends Vue {
  @Prop()
  iconStyle: unknown;

  isOpen: boolean = false;
  selectedLanguage: string = "";

  mounted() {
    this.selectedLanguage = store.getters.ui.getCurrentLanguage();
  }

  get allLanguages() {
    return store.getters.ui.getAvailableLanguages();
  }

  verticalPosition() {
    const wrapper = this.$refs.wrapper as HTMLElement;
    if (wrapper) {
      const bb = wrapper.getBoundingClientRect();
      return window.innerHeight - bb.bottom ;
    }
  }
  horizontalPosition() {
    const wrapper = this.$refs.wrapper as HTMLElement;
    if (wrapper) {
      const bb = wrapper.getBoundingClientRect();
      return bb.x + bb.width;
    }
  }

  async changeSelectedLanguage(l: string) {
    this.selectedLanguage = l;
    try {
      const labels = await axios.get(`/locale/${l}.json`);

      if (labels.status == 200)
        store.actions.ui.setLanguage({
          language: l,
          value: labels.data,
        });
    } catch (err) {}
  }
}
