import axios from "axios";
import { InitModule, MessageService } from "vue-mf-module";
import { InjectRoutes, mainRoute, rootRoute } from "./router";
import { store, vueStore } from "./store";
import merge from "lodash.merge";
import { loadedConfiguration, settings } from "./settings";

declare const DEVELOPMENT;

export async function loadModules(): Promise<void> {
  let modules = {
    imports: {} as Record<string, string>,
    cssimports: [] as string[],
    domains: {} as Record<string, { home: string; modules: string[]; pageTitle: string }>,
    languages: ["it"],
  };
  modules = await LoadAndMergeConfigFile(modules, `/import.map.json`);

  if (DEVELOPMENT)
    modules = await LoadAndMergeConfigFile(modules, `/import.map.dev.json`);
  modules = await LoadAndMergeConfigFile(modules, `/import.map.custom.json`);

  for (const item of modules.cssimports) {
    let l = document.createElement("link") as HTMLLinkElement;
    l.setAttribute("rel", "stylesheet");
    l.setAttribute("href", item);
    document.head.append(l);
  }
  let config: any = { language: "it" };
  config = await LoadAndMergeConfigFile(config, "/service.config.json");

  if (DEVELOPMENT)
    config = await LoadAndMergeConfigFile(config, "/service.config.dev.json");
  config = await LoadAndMergeConfigFile(config, "/service.config.custom.json");

  merge(loadedConfiguration, config);
  Object.assign(settings, config['main'] || {})

  try {
    let currentLanguage = localStorage.getItem('currentLanguage') || config.language;

    let labels = await axios.get(`/locale/${currentLanguage}.json`);

    if (labels.status == 200)
      store.actions.ui.setLanguage({
        language: currentLanguage,
        value: labels.data,
      });
  } catch (err) { }

  if (modules.domains["*"])
    for (const key of modules.domains["*"].modules) {
      const moduleuri = modules.imports[key];
      console.log(`LOADING MODULE: ${key} ... from ---> `, moduleuri);
      await doLoading(key, moduleuri, config);
    }

  if (modules.domains[window.location.hostname]) {

    window.document.title = modules.domains[window.location.hostname].pageTitle || window.document.title;

    for (const key of modules.domains[window.location.hostname].modules) {
      const moduleuri = modules.imports[key];
      console.log(`LOADING MODULE: ${key} ... from ---> `, moduleuri);
      await doLoading(key, moduleuri, config);
    }

    try {
      let route = modules.domains[window.location.hostname].home;
      if (route) {
        mainRoute.redirect = route;
        rootRoute.redirect = route;
      }
    } catch { }
  }

  if (modules.languages && modules.languages.length) {
    store.actions.ui.setAvailableLanguages(modules.languages);
  }

  // RestartRouter();
}

async function LoadAndMergeConfigFile(config: any, file: string): Promise<any> {
  try {
    var response = await axios.get(file);
    if (response.status == 200) {
      merge(config, response.data);
    }
  } catch { }
  return config;
}

async function doLoading(key: string, uri: string, config) {
  try {
    // typescript fa un remap delle parti di systemjs, quindi va ripreso l'oggetto globale in window
    const moduleExports = await (window as any).System.import(uri);

    if (uri.indexOf("vendors.js") >= 0) return;
    let module = await InitModule(moduleExports, vueStore, config[key] || {});

    if (module.routes) InjectRoutes(mainRoute, ...module.routes);
  } catch (err) {
    console.error(`Error loading module ${key}`, err);
  }
}
