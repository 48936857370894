import { store } from "@/store";
import Vue from "vue";
import Component from "vue-class-component";
import { CommonRegistry, IMenuDefinition, MenuHelper, MessageService } from "vue-mf-module";
import { MenuNotifications, menuType } from "vue-mf-module/dist/helpers/MenuHelper";
import menuItem from "./menuItem.vue";
import LangSelector from "@/components/langSelector/langSelector.vue";
import { settings } from "@/settings";

@Component({
  components: { menuItem, LangSelector }
})
export default class drawerMenu extends Vue {
  menu: {
    item: IMenuDefinition;
    children: IMenuDefinition[];
  }[] = [];

  bottommenu: {
    item: IMenuDefinition;
    children: IMenuDefinition[];
  }[] = [];

  settingsmenu: {
    item: IMenuDefinition;
    children: IMenuDefinition[];
  }[] = [];

  get appTitle() {
    return settings.appTitle;
  }

  get securedImage() {
    return CommonRegistry.Instance.getComponent("secured-image");
  }

  get activeMenu() {
    return this.menu.filter(m => !m.item.featureflags || !m.item.featureflags.length || m.item.featureflags.find(f => this.$can(f))).sort((a, b) => (a.item.orderIndex || 10) - (b.item.orderIndex || 10));
  }

  get bottomMenu() {
    return this.bottommenu.filter(m => !m.item.featureflags || !m.item.featureflags.length || m.item.featureflags.find(f => this.$can(f)));
  }

  get settingsMenu() {
    return this.settingsmenu.filter(m => !m.item.featureflags || !m.item.featureflags.length || m.item.featureflags.find(f => this.$can(f)));
  }

  isOpen = false;

  get expanded() {
    return store.getters.ui.isMenuExpanded();
  }

  get asideStyle() {
    const currentNames = this.$route.matched.map(r => r.name);
    const current = this.menu.find(m => currentNames.includes(m.item.routeName) && m.item.meta && m.item.meta.themeColorDark);

    if (current && current.item.meta && current.item.meta.themeColor)
      return { background: current.item.meta.themeColor };

    return {};
  }


  goHome() {
    this.$router.push({ name: "home" });
  }

  userinfo = null;
  workspace: { id: string } = null;

  get imageUri() {
    return `${settings.ImageServerUri}/generic/workspace/image/${this.workspace.id}.png/resize?height=48`;
  }

  async mounted() {
    MenuHelper.Instance.Notifications.on(MenuNotifications.menuDefinitionAdded, () => this.loadMenu());
    this.loadMenu();
    this.userinfo = await MessageService.Instance.ask("WHO_AM_I");
    this.workspace = await MessageService.Instance.ask("MY_WORKSPACE");
  }

  async loadMenu() {
    this.menu = MenuHelper.Instance.getMenu(menuType.drawer);
    this.bottommenu = MenuHelper.Instance.getMenu(menuType.footer);
    this.settingsmenu = MenuHelper.Instance.getMenu(menuType.settings);
  }

  goto(route: string) {
    this.$router.push(route).catch(() => { });;
  }

  toggleMenu() {
    store.actions.ui.toggleMenu(!this.expanded);
  }

  getIconStyle(menu: {
    item: IMenuDefinition;
    children: IMenuDefinition[];
  }) {
    const currentNames = this.$route.matched.map(r => r.name);
    const current = this.menu.find(m => currentNames.includes(m.item.routeName) && m.item.meta && m.item.meta.themeColorDark);

    if (current && current != menu)
      return { color: current.item.meta.themeColorDark };

    return {};
  }

}
